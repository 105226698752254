import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st3{fill:#fff}"}</style>
    <g id="Layer_1">
      <circle
        cx={32}
        cy={32}
        r={32}
        style={{
          fill: "#4f5d73",
        }}
      />
      <g
        style={{
          opacity: 0.2,
        }}
      >
        <path
          d="M55.9 32.5c-.7-12.1-10.4-21.7-22.4-22.4V10h-3v.1c-12.1.7-21.7 10.3-22.4 22.4H8v3h.1c.7 12.1 10.4 21.7 22.4 22.4v.1h3v-.1c12.1-.7 21.7-10.4 22.4-22.4h.1l-.1-3zm-10.4 0c-.1-2.8-.5-5.4-1-7.9 1.8-.6 3.5-1.3 5.1-2.2 1.9 2.9 3.1 6.4 3.4 10.1h-7.5zm-6.6-14c.7 1.2 1.3 2.6 1.8 4-2.3.5-4.7.8-7.2.9V13.3c1.9.6 3.9 2.4 5.4 5.2zm-8.4-5.2v10.2c-2.5-.1-4.9-.4-7.2-.9.5-1.4 1.1-2.8 1.8-4 1.5-2.9 3.5-4.7 5.4-5.3zm0 13.2v6h-9c.1-2.5.4-4.9.9-7.1 2.6.6 5.3 1 8.1 1.1zm0 9v6.2c-2.8.1-5.5.4-8 1-.5-2.3-.9-4.7-1-7.2h9zm0 9.2v10c-1.9-.6-3.9-2.5-5.4-5.2-.7-1.2-1.2-2.5-1.7-3.9 2.2-.5 4.6-.8 7.1-.9zm3 10v-10c2.5.1 4.9.4 7.2.9-.5 1.4-1.1 2.7-1.7 3.9-1.6 2.8-3.6 4.6-5.5 5.2zm0-13v-6.2h9c-.1 2.5-.4 5-1 7.2-2.5-.6-5.2-.9-8-1zm0-9.2v-6c2.8-.1 5.5-.5 8-1 .5 2.2.8 4.6.9 7.1h-8.9zM47.7 20c-1.2.7-2.6 1.2-4 1.7-.9-2.8-2.2-5.3-3.7-7.2 2.9 1.3 5.5 3.2 7.7 5.5zm-23.6-5.4c-1.5 1.9-2.7 4.4-3.7 7.2-1.4-.5-2.8-1-4-1.7 2.1-2.4 4.7-4.3 7.7-5.5zm-9.6 7.8c1.5.8 3.3 1.6 5.1 2.2-.6 2.4-.9 5.1-1 7.9h-7.5c.2-3.7 1.4-7.1 3.4-10.1zm4 13.1c.1 2.8.5 5.5 1.1 8-1.8.6-3.5 1.3-5.1 2.1-2-2.9-3.2-6.4-3.5-10.2h7.5zm-2.1 12.6c1.2-.6 2.6-1.2 4-1.7.9 2.8 2.2 5.2 3.6 7-2.9-1.2-5.5-3-7.6-5.3zm23.5 5.3c1.5-1.9 2.7-4.3 3.6-7 1.4.5 2.8 1 4 1.7-2 2.3-4.6 4.1-7.6 5.3zm9.5-7.7c-1.5-.8-3.2-1.5-5.1-2.1.6-2.5 1-5.2 1.1-8h7.5c-.2 3.6-1.5 7.1-3.5 10.1z"
          style={{
            fill: "#231f20",
          }}
        />
      </g>
      <path
        className="st3"
        d="M32 11c11.6 0 21 9.4 21 21s-9.4 21-21 21-21-9.4-21-21 9.4-21 21-21m0-3C18.7 8 8 18.7 8 32s10.7 24 24 24 24-10.7 24-24S45.3 8 32 8z"
      />
      <path
        className="st3"
        d="M32 11c2.4 0 5 2 6.9 5.5 2.3 4.1 3.6 9.6 3.6 15.5s-1.3 11.4-3.6 15.5C37 51 34.4 53 32 53s-5-2-6.9-5.5c-2.3-4.1-3.6-9.6-3.6-15.5s1.3-11.4 3.6-15.5C27 13 29.6 11 32 11m0-3c-7.5 0-13.5 10.7-13.5 24s6 24 13.5 24 13.5-10.7 13.5-24S39.5 8 32 8z"
      />
      <path className="st3" d="M30.5 8h3v48h-3z" />
      <path
        className="st3"
        d="M32 24.5c-7.4 0-14.4-1.9-19.3-5.1l1.7-2.5c4.3 2.9 10.7 4.6 17.6 4.6 6.8 0 13.2-1.7 17.6-4.6l1.7 2.5c-4.9 3.2-11.9 5.1-19.3 5.1zM49.6 47.2c-4.3-2.9-10.7-4.6-17.6-4.6-6.8 0-13.2 1.7-17.6 4.6l-1.7-2.5c4.8-3.2 11.8-5.1 19.3-5.1 7.4 0 14.4 1.9 19.3 5.1l-1.7 2.5zM8 30.5h48v3H8z"
      />
    </g>
  </svg>
)

export default SvgComponent
