import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";

export default function Priorities() {
  return (
    <Wrapper id="blog">
      <div className="lightBg" style={{padding: '30px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Priorities if Re-Elected</h1>
            <p className="font15">
              If given the chance to represent Burlington once again, these are some of the top priorities identified (in no particular order):
              
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Student Success"
                text="Ensuring ALL students have what they need to be successful and feel safe and welcome."
                
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Accessibility"
                text="Improving accessibility in our system and working to remove both physical and attitudinal barriers so ALL students can participate and have equal opportunity."
                
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Supporting marginalized communities"
                text="Continued training in key areas including anti-Black racism, LGBTQ+IA2S, Indigenous, and disability. More work is required in developing and delivering training, in partnership with these communities, to ensure it resonates appropriately and effectively."
                
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="More Transparency"
                text="Improve transparency and communication with our parents, staff, and students. Better and more effective communication leads to better and more effective relationships and outcomes."
                
              />
            </div>
            
          </div>
        </div>
      </div>
      
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;