import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1{opacity:.2}.st2{fill:#231f20}"}</style>
    <g id="Layer_1">
      <circle
        cx={32}
        cy={32}
        r={32}
        style={{
          fill: "#77b3d4",
        }}
      />
      <g className="st1">
        <path
          className="st2"
          d="M48 50c0 2.2-1.8 4-4 4H20c-2.2 0-4-1.8-4-4V18c0-2.2 1.8-4 4-4h24c2.2 0 4 1.8 4 4v32z"
        />
      </g>
      <path
        d="M48 48c0 2.2-1.8 4-4 4H20c-2.2 0-4-1.8-4-4V16c0-2.2 1.8-4 4-4h24c2.2 0 4 1.8 4 4v32z"
        style={{
          fill: "#4f5d73",
        }}
      />
      <g className="st1">
        <path
          className="st2"
          d="M35 14v7.8c0 .3.2.5.4.6h.3c.2 0 .3-.1.5-.2l2.9-2.8 2.9 2.8c.1.1.3.2.5.2h.3c.2-.1.4-.3.4-.6V14H35z"
        />
      </g>
      <path
        d="M35 12v7.8c0 .3.2.5.4.6h.3c.2 0 .3-.1.5-.2l2.9-2.8 2.9 2.8c.1.1.3.2.5.2h.3c.2-.1.4-.3.4-.6V12H35z"
        style={{
          fill: "#c75c5c",
        }}
      />
      <path
        style={{
          fill: "#e0e0d1",
        }}
        d="M20 12h3v40h-3z"
      />
    </g>
  </svg>
)

export default SvgComponent
