import React from "react";
import styled from "styled-components";
import ServiceBox from '../Elements/ServiceBox';
export default function Experience() {
  return (
    <Wrapper className="whiteBg" id="projects">
      <div >
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Experience</h1>

          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
              
                icon="megaphone"
                title="HCDSB Trustee"
                subtitle="Elected in 2018, Brenda Agnew has served four years as Burlington Trustee including one year as Vice Chair of the Board of Trustees."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="brightness"
                title="SEAC"
                subtitle="A member of the Special Education Advisory Committee  since 2014, currently serving as Chair of the committee, Brenda Agnew has consistently advocated for inclusion and accessibility."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="rainbow"
                title="Diversity, Equity & Inclusion"
                subtitle="During her time as a trustee, she has demonstrated a committment to championing diversity and equity in our schools. "
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="globe" title="Volunteer Work" subtitle="Has served the Burlington community through involvement in a number of charitiable and community organizations." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox icon="heart" title="Easter Seals Board of Directors" />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
            <ServiceBox icon="key" title="Burlington Accessibility Advisory Committee" />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="globe2" title="AACPDM Community Council Member" />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox icon="booklet" title="PONDA Network Family Advisor" />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="shoeprints" title="Child-Bright Citizen Engagement Committee Member" />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="contacts" title="CP-Net Parent and Family Advisor" />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
      
    </Wrapper>
  );
}

const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  align-Items: flex-start;
`;
const ServiceBoxWrapper = styled.div`
  margin-right: 5%;
  padding: 20px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
