import React from "react";
import styled from "styled-components";
import InnerHTML from 'dangerously-set-html-content';

var donation = '<script src="https://donorbox.org/widget.js" paypalExpress="false"></script><iframe src="https://donorbox.org/embed/re-elect-brenda-agnew-hcdsb-trustee" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 250px; max-height:none!important"></iframe>';


export default function AboutMe() {
  return (
    <Wrapper id="services" className="container">
      <div className="lightBg" style={{ padding: "30px 0" }}>
        <div className="container">
        <HeaderInfo>
        <LeftSide id="services2" >
              <h1 className="font40 extraBold">About Me</h1>
              <p className="font13">
              Brenda Agnew is a wife and a mother to two teen sons attending school at HCDSB in Burlington. Brenda Agnew was elected as Burlington trustee in 2018, has been involved in school council since 2012, and with the HCDSB Special Education Advisory Committee since 2014. As a parent of a son who has Cerebral Palsy and is profoundly deaf, Brenda credits her experiences raising him with igniting a passion to advocate for inclusion, diversity and accessibility, which also drives much of the volunteer work with which she is involved. She is a very active member of the Burlington community and am a big supporter of our schools and our community organizations.
              <br/><br/>
              As a candidate, Brenda Agnew strives to be a fair, committed, dedicated and passionate advocate for our education system. Her personal experience as a parent to a child with a disability and special education needs has provided unique (albeit challenging) situations that she has had to navigate both within education and in the outside world. Brenda firmly believes that we are all created equal and should be treated as such, while also recognizing that our needs are all different and we have to be able to create ways to meet those needs. She has proven that she is not afraid to ask the difficult questions, challenge the status quo, and hold people accountable, including herself. She has shown through her work at the HCDSB that she will work hard to establish and maintain relationships and always aim to work collaboratively to find solutions.
              </p>
          </LeftSide>
          <RightSide id="donate">
          <h1 className="font40 extraBold">Donate</h1>
              
            <div className="flexSpaceCenter" style={{justifyContent:'center'}}>
                <InnerHTML html={donation} />
            </div>
          </RightSide>          
          </HeaderInfo>
        
        </div> 
      </div>
    </Wrapper>
  );
}
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 40%;
  height: 100%;
  margin-left:auto;
  
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  display:flex;
  @media (max-width: 860px) {
    display:unset;
    text-align: center;
  }
`;
