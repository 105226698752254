import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {".st1{opacity:.2;fill:#231f20}.st2{fill:#c75c5c}.st3{fill:#4f5d73}"}
    </style>
    <circle
      cx={32}
      cy={32}
      r={32}
      style={{
        fill: "#76c2af",
      }}
    />
    <path
      className="st1"
      d="M24.6 41.9c.4 1.1-.2 2-1.4 2h-2c-1.1 0-2.3-.9-2.6-2l-3.2-10c-.4-1.1.2-2 1.4-2h2c1.1 0 2.3.9 2.6 2l3.2 10z"
    />
    <path
      className="st1"
      d="M24 46.9c.4 1.1-.2 2-1.4 2h-2c-1.1 0-2-.1-2.4-1.2l-3.5-10.8c-.4-1.1.2-2 1.4-2h2c1.1 0 2.3.9 2.6 2l3.3 10z"
    />
    <path
      className="st2"
      d="M24.6 39.9c.4 1.1-.2 2-1.4 2h-2c-1.1 0-2.3-.9-2.6-2l-3.2-10c-.4-1.1.2-2 1.4-2h2c1.1 0 2.3.9 2.6 2l3.2 10z"
    />
    <path
      className="st3"
      d="M24 44.9c.4 1.1-.2 2-1.4 2h-2c-1.1 0-2.2-.5-2.6-1.6l-3.3-10.4c-.4-1.1.2-2 1.4-2h2c1.1 0 2.3.9 2.6 2l3.3 10z"
    />
    <path
      className="st1"
      d="M48 28.9V18.5c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v1c-2.8 2.5-6.3 6.4-16 6.4H14c-1.1 0-2 .9-2 2-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2 0 1.1.9 2 2 2h15c9.7 0 13.2 3.9 16 6.4v1.1c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5V36.9c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2z"
    />
    <path
      d="M50 32.9c0 1.1-.9 2-2 2h-8c-1.1 0-2-.9-2-2v-4c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v4z"
      style={{
        fill: "#e0e0d1",
      }}
    />
    <path
      className="st3"
      d="M22 33.9c0 1.1-.9 2-2 2h-8c-1.1 0-2-.9-2-2v-6c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v6z"
    />
    <path
      d="M46.1 16.6c-3 2.3-6.1 7.3-17.1 7.3H16l-1 14h14c11 0 14.1 5 17.1 7.3V16.6z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      className="st2"
      d="M18 37.9h-4c-1.1 0-2-.9-2-2v-10c0-1.1.9-2 2-2h4v14z"
    />
    <path
      className="st3"
      d="M48 45.4c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5V16.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v28.9z"
    />
  </svg>
)

export default SvgComponent
