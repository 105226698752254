import React from "react";
import styled from "styled-components";
// Components
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Testimonials() {
  return (
    <Wrapper id="testimonials">
      <div className="whiteBg" style={{padding: '0px 0px 250px 0px'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Testimonials</h1>
            <p className="font15">
              Here are some comments about Brenda Agnew's experience as an HCDSB Trustee, reprinted with permission:
              
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;