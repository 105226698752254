import React from 'react';
import Carousel from "react-material-ui-carousel";
import img1 from "../../assets/img/gallery/1.jpg";
import img2 from "../../assets/img/gallery/2.jpg";
import img3 from "../../assets/img/gallery/3.jpg";
import img4 from "../../assets/img/gallery/4.jpg";
import img5 from "../../assets/img/gallery/5.jpg";
import img6 from "../../assets/img/gallery/6.jpg";
import img7 from "../../assets/img/gallery/7.jpg";
import img8 from "../../assets/img/gallery/8.jpg";
import img9 from "../../assets/img/gallery/9.jpg";
import img10 from "../../assets/img/gallery/10.jpg";
import img11 from "../../assets/img/gallery/11.jpg";
import img12 from "../../assets/img/gallery/12.jpg";
import styled from "styled-components";

import { useState, useLayoutEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
export default function ImgCarousel() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    return (
        <Wrapper className="container flexSpaceCenter">
        <Container>
        <Carousel a navButtonsAlwaysVisible={true} stopAutoPlayOnHover={true} autoPlay={true} navButtonsAlwaysInvisible={false} interval={4000} height={windowDimensions.width > 960 ? '50vh' : windowDimensions.height/20 + 'vh'}  >
                        <img src={img1} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img2} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img3} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img4} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img5} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img6} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img7} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img8} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img9} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img10} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img11} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                        <img src={img12} style={{ display:'flex', margin:'auto auto'}} className='radius8' alt=""/>
                     </Carousel>
        </Container>
    </Wrapper>
        );
    }

    
const Wrapper = styled.section`
padding-bottom: 20px;
width: 100%;
& img{
    max-width:50vw;
    max-height:50vh;
};
@media (max-width: 960px) {
  flex-direction: column;
};

`;

const Container = styled.div`
width: 80%;
max-width: 900px;
margin: auto auto;
}
* {
margin: 0;
}`;