import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import AboutMe from "../components/Sections/AboutMe";
import Priorities from "../components/Sections/Priorities";
import Footer from "../components/Sections/Footer"
import Experience from "../components/Sections/Experience";
import Testimonials from "../components/Sections/Testimonials";
import Carousel from "../components/Sections/Carousel";
export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Carousel />
      <AboutMe />
      <Experience />
      <Priorities />
      <Testimonials />
      <Footer /> 
    </>
  );
}


