import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1{opacity:.2}.st2{fill:#231f20}.st5{fill:#e0995e}"}</style>
    <g id="Layer_1">
      <circle
        cx={31.8}
        cy={32}
        r={32}
        style={{
          fill: "#76c2af",
        }}
      />
      <g className="st1">
        <path
          className="st2"
          d="M28 23v-9H15c-1.7 0-3 1.3-3 3v34c0 1.7 1.3 3 3 3h22c1.7 0 3-1.3 3-3V26h-9c-1.7 0-3-1.3-3-3z"
        />
      </g>
      <path
        d="M37 12H15c-1.7 0-3 1.3-3 3v34c0 1.7 1.3 3 3 3h22c1.7 0 3-1.3 3-3V15c0-1.7-1.3-3-3-3z"
        style={{
          fill: "#e0e0d1",
        }}
      />
      <g className="st1">
        <path className="st2" d="M16 19h10v2H16z" />
      </g>
      <g className="st1">
        <path className="st2" d="M16 35h20v2H16z" />
      </g>
      <g className="st1">
        <path className="st2" d="M16 30h20v2H16z" />
      </g>
      <g className="st1">
        <path className="st2" d="M16 25h20v2H16z" />
      </g>
      <g className="st1">
        <path className="st2" d="M16 40h20v2H16z" />
      </g>
      <g className="st1">
        <path className="st2" d="M16 45h20v2H16z" />
      </g>
      <g className="st1">
        <path
          className="st2"
          d="M55.1 21.9 48 14.8c-1.2-1.2-3.1-1.2-4.2 0l-3.5 3.5-.7.7-16.3 16.3-1.9 9.4-.9 4.7 4.7-.9 9.4-1.9 16.3-16.3.7-.7 3.5-3.5c1.2-1.2 1.2-3.1 0-4.2z"
        />
      </g>
      <path
        d="M50.9 28.4c-1.2 1.2-3.1 1.2-4.2 0l-7.1-7.1c-1.2-1.2-1.2-3.1 0-4.2l4.2-4.2c1.2-1.2 3.1-1.2 4.2 0l7.1 7.1c1.2 1.2 1.2 3.1 0 4.2l-4.2 4.2z"
        style={{
          fill: "#c75c5c",
        }}
      />
      <path
        transform="rotate(45.001 33.202 26.313)"
        className="st5"
        d="M31.2 14.3h4v24h-4z"
      />
      <path
        transform="rotate(45.001 36.737 31.263)"
        style={{
          fill: "#f5cf87",
        }}
        d="M32.7 18.3h8v26h-8z"
      />
      <path
        transform="rotate(45.001 41.687 34.799)"
        className="st5"
        d="M39.7 22.8h4v24h-4z"
      />
      <path
        style={{
          fill: "#4f5d73",
        }}
        d="m20.5 47.5 4.7-.9-3.8-3.8z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m23.3 33.4-1.9 9.4 3.8 3.8 9.4-1.9-1.4-1.4v-2.8h-5.7v-5.7h-2.8z"
      />
    </g>
  </svg>
)

export default SvgComponent
