import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1{opacity:.2}.st2{fill:#231f20}"}</style>
    <g id="Layer_1">
      <circle
        cx={32}
        cy={32}
        r={32}
        style={{
          fill: "#76c2af",
        }}
      />
      <g className="st1">
        <path
          className="st2"
          d="M46 45c0 2.2-1.8 4-4 4H22c-2.2 0-4-1.8-4-4V25c0-2.2 1.8-4 4-4h20c2.2 0 4 1.8 4 4v20z"
        />
      </g>
      <path
        d="M22 45c-1.1 0-2-.9-2-2V23c0-1.1.9-2 2-2h20c1.1 0 2 .9 2 2v20c0 1.1-.9 2-2 2H22z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M42 23v20H22V23h20m0-4H22c-2.2 0-4 1.8-4 4v20c0 2.2 1.8 4 4 4h20c2.2 0 4-1.8 4-4V23c0-2.2-1.8-4-4-4z"
        style={{
          fill: "#4f5d73",
        }}
      />
      <g className="st1">
        <path
          className="st2"
          d="M32.5 41c-.6 0-1.2-.3-1.6-.8l-6.1-8c-.7-.9-.5-2.1.4-2.8.9-.7 2.1-.5 2.8.4l4.2 5.5c2.3-4.2 7.3-11.8 15.3-16.9.9-.6 2.2-.3 2.8.6.6.9.3 2.2-.6 2.8-10.2 6.6-15.2 18-15.3 18.1-.3.7-.9 1.1-1.6 1.2-.2-.1-.3-.1-.3-.1z"
        />
      </g>
      <path
        d="M32.5 39c-.6 0-1.2-.3-1.6-.8l-6.1-8c-.7-.9-.5-2.1.4-2.8.9-.7 2.1-.5 2.8.4l4.2 5.5c2.3-4.2 7.3-11.8 15.3-16.9.9-.6 2.2-.3 2.8.6.6.9.3 2.2-.6 2.8-10.2 6.6-15.2 18-15.3 18.1-.3.7-.9 1.1-1.6 1.2-.2-.1-.3-.1-.3-.1z"
        style={{
          fill: "#c75c5c",
        }}
      />
    </g>
  </svg>
)

export default SvgComponent
