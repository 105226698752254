import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <circle
      cx={32}
      cy={32}
      r={32}
      style={{
        fill: "#e0995e",
      }}
    />
    <g
      style={{
        opacity: 0.2,
      }}
    >
      <path
        d="M47 18h-4c-.2 0-.5.1-.7.2L28.5 30.4c-.7-.2-1.8-.4-2.5-.4-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10c0-1.6-.3-2.7-.7-3.6l3.4-1.2c.4-.1.7-.5.7-1l-.1-3.8 3.8-.3c.5 0 .9-.5.9-1l.1-4.6 3-.7c.5-.1.8-.5.8-1v-4c.1-.4-.3-.8-.9-.8zM23 40c1.2 0 2.2.7 2.7 1.7.2.4.3.8.3 1.3 0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3z"
        style={{
          fill: "#231f20",
        }}
      />
    </g>
    <path
      d="M47 16h-4c-.2 0-.5.1-.7.2L28.5 28.4c-.7-.2-1.8-.4-2.5-.4-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10c0-1.6-.3-2.7-.7-3.6l3.4-1.2c.4-.1.7-.5.7-1l-.1-3.8 3.8-.3c.5 0 .9-.5.9-1l.1-4.6 3-.7c.5-.1.8-.5.8-1v-4c.1-.4-.3-.8-.9-.8zM23 38c1.2 0 2.2.7 2.7 1.7.2.4.3.8.3 1.3 0 1.7-1.3 3-3 3s-3-1.3-3-3 1.3-3 3-3z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)

export default SvgComponent
