import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st1{opacity:.2}.st2{fill:#231f20}.st3{fill:#e0e0d1}.st4{fill:#fff}.st5{opacity:.2;fill:#231f20}"
      }
    </style>
    <circle
      cx={32}
      cy={32}
      r={32}
      style={{
        fill: "#c75c5c",
      }}
    />
    <g className="st1">
      <path
        className="st2"
        d="M25 43.3v7.9c0 .5.6 1.3 1 1.5l5.3 3c.4.2 1.1.2 1.5 0l5.3-3c.4-.2 1-1.1 1-1.5v-7.9c-2.1 1.1-4.5 1.7-7 1.7-2.6 0-5-.6-7.1-1.7z"
      />
    </g>
    <path
      className="st3"
      d="M25 41.3v7.9c0 .5.6 1.3 1 1.5l5.3 3c.4.2 1.1.2 1.5 0l5.3-3c.4-.2 1-1.1 1-1.5v-7.9c-2.1 1.1-4.5 1.7-7 1.7-2.6 0-5-.6-7.1-1.7z"
    />
    <g className="st1">
      <circle className="st2" cx={32} cy={34.6} r={14.4} />
    </g>
    <circle className="st4" cx={32} cy={32} r={14.4} />
    <path
      className="st5"
      d="M33.5 15.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5v-2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v2zM50.5 35.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h2c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-2zM13.5 32.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h2zM24 17.2c.4.7.2 1.6-.5 2-.7.4-1.6.2-2-.5l-1-1.7c-.4-.7-.2-1.6.5-2 .7-.4 1.6-.2 2 .5l1 1.7zM48.8 26c-.7.4-1.6.2-2-.5-.4-.7-.2-1.6.5-2l1.7-1c.7-.4 1.6-.2 2 .5.4.7.2 1.6-.5 2l-1.7 1zM15.2 42c.7-.4 1.6-.2 2 .5.4.7.2 1.6-.5 2l-1.7 1c-.7.4-1.6.2-2-.5-.4-.7-.2-1.6.5-2l1.7-1zM16.7 23.5c.7.4 1 1.3.5 2-.4.7-1.3 1-2 .5l-1.7-1c-.7-.4-1-1.3-.5-2 .4-.7 1.3-1 2-.5l1.7 1zM47.3 44.5c-.7-.4-1-1.3-.5-2 .4-.7 1.3-1 2-.5l1.7 1c.7.4 1 1.3.5 2-.4.7-1.3 1-2 .5l-1.7-1zM42.5 18.7c-.4.7-1.3 1-2 .5-.7-.4-1-1.3-.5-2l1-1.7c.4-.7 1.3-1 2-.5.7.4 1 1.3.5 2l-1 1.7z"
    />
    <circle className="st4" cx={32} cy={32} r={14.4} />
    <path
      className="st3"
      d="M33.5 13.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5v-2c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v2zM50.5 33.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h2c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-2zM13.5 30.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-2c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h2zM24 15.2c.4.7.2 1.6-.5 2-.7.4-1.6.2-2-.5l-1-1.7c-.4-.7-.2-1.6.5-2 .7-.4 1.6-.2 2 .5l1 1.7zM48.8 24c-.7.4-1.6.2-2-.5-.4-.7-.2-1.6.5-2l1.7-1c.7-.4 1.6-.2 2 .5.4.7.2 1.6-.5 2l-1.7 1zM15.2 40c.7-.4 1.6-.2 2 .5.4.7.2 1.6-.5 2l-1.7 1c-.7.4-1.6.2-2-.5-.4-.7-.2-1.6.5-2l1.7-1zM16.7 21.5c.7.4 1 1.3.5 2-.4.7-1.3 1-2 .5l-1.7-1c-.7-.4-1-1.3-.5-2 .4-.7 1.3-1 2-.5l1.7 1zM47.3 42.5c-.7-.4-1-1.3-.5-2 .4-.7 1.3-1 2-.5l1.7 1c.7.4 1 1.3.5 2-.4.7-1.3 1-2 .5l-1.7-1zM42.5 16.7c-.4.7-1.3 1-2 .5-.7-.4-1-1.3-.5-2l1-1.7c.4-.7 1.3-1 2-.5.7.4 1 1.3.5 2l-1 1.7z"
    />
  </svg>
)

export default SvgComponent
