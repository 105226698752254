import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st2{fill:#231f20}.st3{fill:#fff}"}</style>
    <g id="Layer_1">
      <circle
        cx={32}
        cy={32}
        r={32}
        style={{
          fill: "#77b3d4",
        }}
      />
      <g
        style={{
          opacity: 0.2,
        }}
      >
        <path
          className="st2"
          d="M32 11.9c-12.2 0-22 9.8-22 22s9.8 22 22 22 22-9.8 22-22-9.8-22-22-22zM14.1 24.7c.2.8-.2 2.4-.5 4.5-.4 2.5 1.6 2 1.6 4.4s1.8 3.4 1.5 3.6c-.2.2-1.2.6.2 3.7s2.7 4 2.8 5.7c.2 1.7 2 4.2 2 4.2l.1.5c-6-3.5-10-10-10-17.4 0-3.3.8-6.4 2.3-9.2zM24 51.8c-.1-.4.1-2.8 3-3.8s2.4-2.2 3.3-3.5c.9-1.2 1-2.2-.6-2.5-1.6-.4-3.4-.7-4.8-3-1.5-2.3-3.5-2.4-4.6-3.1-1.1-.7-2-.6-3.3-.8-1.5-.1-1-3.9-1-4.7.1-.8-.8.3-1.5-.3s.6-3.2 1.9-3.1c1.3.1 1 .7 1.2 2.1.2 1.4.7 1.1.8.6.1-.4.2-2.3.6-2.9s.5-2.2 2-2.1c1.4.1 1.4-1.3 2.5-.9s1.8.1 1.1-.5-.3-1.2.9-.8c1.2.4 1.2-1.7 1-2.7-.2-1.1-1.1-1.4-2.1-.7-1.1.7-1.9.4-1.3-.7.5-1.1 2.7-.2 4 .4 1.3.5 1.6-1.4.9-2.4-.7-1-.8-.5-2.8-.4-.8.1-.7-.4-.3-.9 2.2-.8 4.6-1.3 7.1-1.3s5 .5 7.2 1.3c.3.4.4.7.2.8-.8.5-1.5.1-1.3 1.6.2 1.5 1.4 3.4 2.3 2.8.9-.6 2.4.2 1.1 1-1.3.8-1.7.8-2.3 1.3-.6.5-.3.3.3 1s.5.8-.3 1.2c-.9.3-1.4 1.1-.5 2 .8.9 1.4 1.6 2.3 0 .9-1.6 1.1-3 1.9-2.5.8.4 1.7 1.7 2.3 1 .6-.7.5-1.2 1.3-.7s3.7.6 1.8 2c-2 1.4-1.6 2.1-2.5 1.9-.9-.2-5.1-1.1-6.1 1.3-1 2.3-4.5 4.2-2.6 6.5 1.8 2.3 1.4 3.9 4 4.3 2.6.3 3.9-.8 4.6.4s.1 1.3.2 3.2c0 .9.5 2.6 1 4.1-3.7 3.8-8.8 6.2-14.5 6.2-2.9 0-5.6-.6-8.1-1.7-.3-.7-.3-.9-.3-1z"
        />
        <path
          className="st2"
          d="M32.1 18.2c.6-.4.7.1 1.2-1.6.5-1.8-2.1-2-3.3-1.8-1.2.2-.7.7-.7.7.4.7.7 3.1-.1 3.9-.7.9.6 1.1 1.2.2.7-.7 1-1 1.7-1.4z"
        />
      </g>
      <path
        className="st3"
        d="M32 10.1c-12.2 0-22 9.8-22 22s9.8 22 22 22 22-9.8 22-22-9.8-22-22-22zM14.1 22.9c.2.8-.2 2.4-.5 4.5-.4 2.5 1.6 2 1.6 4.4s1.8 3.4 1.5 3.6c-.2.2-1.2.6.2 3.7s2.7 4 2.8 5.7c.2 1.7 2 4.2 2 4.2l.1.5c-6-3.5-10-10-10-17.4 0-3.3.8-6.4 2.3-9.2zM24 50c-.1-.4.1-2.8 3-3.8s2.4-2.2 3.3-3.5c.9-1.2 1-2.2-.6-2.5-1.6-.4-3.4-.7-4.8-3-1.5-2.3-3.5-2.4-4.6-3.1-1.1-.7-2-.6-3.3-.8-1.3-.2-.9-3.9-.8-4.7.1-.8-.8.3-1.5-.3s.6-3.2 1.9-3.1c1.3.1 1 .7 1.2 2.1.2 1.4.7 1.1.8.6.1-.4.2-2.3.6-2.9.4-.6.5-2.2 2-2.1 1.4.1 1.4-1.3 2.5-.9s1.8.1 1.1-.5-.3-1.2.9-.8c1.2.4 1.2-1.7 1-2.7-.2-1.1-1.1-1.4-2.1-.7-1.1.7-1.9.4-1.3-.7.5-1.1 2.7-.2 4 .4 1.3.5 1.6-1.4.9-2.4-.7-1-.8-.5-2.8-.4-.8.1-.7-.4-.3-.9 2.2-.8 4.6-1.3 7.1-1.3s5 .5 7.2 1.3c.3.4.4.7.2.8-.8.5-1.5.1-1.3 1.6.2 1.5 1.4 3.4 2.3 2.8.9-.6 2.4.2 1.1 1-1.3.8-1.7.8-2.3 1.3-.6.5-.3.3.3 1s.5.8-.3 1.2c-.9.3-1.4 1.1-.5 2 .8.9 1.4 1.6 2.3 0 .9-1.6 1.1-3 1.9-2.5.8.4 1.7 1.7 2.3 1 .6-.7.5-1.2 1.3-.7s3.7.6 1.8 2c-2 1.4-1.6 2.1-2.5 1.9-.9-.2-5.1-1.1-6.1 1.3-1 2.3-4.5 4.2-2.6 6.5 1.8 2.3 1.4 3.9 4 4.3 2.6.3 3.9-.8 4.6.4.7 1.2.1 1.3.2 3.2 0 .9.5 2.6 1 4.1-3.7 3.8-8.8 6.2-14.5 6.2-2.9 0-5.6-.6-8.1-1.7-.5-.7-.5-.9-.5-1z"
      />
      <path
        className="st3"
        d="M32.1 16.4c.6-.4.7.1 1.2-1.6.5-1.8-2.1-2-3.3-1.8-1.2.2-.7.7-.7.7.4.7.7 3.1-.1 3.9-.7.9.6 1.1 1.2.2s1-1 1.7-1.4z"
      />
    </g>
  </svg>
)

export default SvgComponent
