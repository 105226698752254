import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <circle
      cx={32}
      cy={32}
      r={32}
      style={{
        fill: "#c75c5c",
      }}
    />
    <g
      style={{
        opacity: 0.2,
      }}
    >
      <path
        d="M50 31c-.1-5.5-4.6-10.4-10.1-10.4-3.2 0-6 1.7-7.9 4.1-1.9-2.5-4.7-4.1-7.9-4.1-5.5 0-10 4.9-10.1 10.4V31.6c.5 14.1 17.8 19.8 17.8 19.8S49.4 45.7 50 31.6V31c0 .1 0 0 0 0z"
        style={{
          fill: "#231f20",
        }}
      />
    </g>
    <path
      d="M50 29c-.1-5.5-4.6-10.4-10.1-10.4-3.2 0-6 1.7-7.9 4.1-1.9-2.5-4.7-4.1-7.9-4.1-5.5 0-10 4.9-10.1 10.4V29.6c.5 14.1 17.8 19.8 17.8 19.8S49.4 43.7 50 29.6V29c0 .1 0 0 0 0z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)

export default SvgComponent
