import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Brenda Agnew is a trustee who fights for the well-being of the students of the Halton Catholic District School Board. She is not afraid to stand up for what she believes is right and just and will steadfastly work until the job is done. Brenda demonstrates professionalism in her role as trustee and Vice Chair. She will ask the tough questions and keeps the lens of student achievement and well-being at the forefront of her decision-making at the board table."
            author="Andrea Grebenc"
          />
          <TestimonialBox
            text="As Burlington knows, there is no stronger or better advocate for our kids than Brenda Agnew.  Sharp as a whip, intuitive, compassionate for both parents and kids, can make anyone smile and best of all, she is there for our kids, and us as parents, making sure we have a voice and are heard.  Brenda has continuously tackled issues important for our school community and has proven to be the strongest advocate we need at the Halton Catholic District School Board. "
            author="Colleen Brooks, HCDSB Parent"
          />


        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
