import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {".st1{opacity:.2}.st2{fill:#231f20}.st8{fill:#e0e0d1}.st9{fill:#f5cf87}"}
    </style>
    <g id="Layer_1">
      <circle
        cx={32}
        cy={32}
        r={32}
        style={{
          fill: "#c75c5c",
        }}
      />
      <g className="st1">
        <path
          className="st2"
          d="M49 26c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2s2 .9 2 2v3c0 1.1-.9 2-2 2z"
        />
      </g>
      <g className="st1">
        <path
          className="st2"
          d="M49 35c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2s2 .9 2 2v3c0 1.1-.9 2-2 2z"
        />
      </g>
      <g className="st1">
        <path
          className="st2"
          d="M49 44c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2s2 .9 2 2v3c0 1.1-.9 2-2 2z"
        />
      </g>
      <path
        d="M49 24c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2s2 .9 2 2v3c0 1.1-.9 2-2 2z"
        style={{
          fill: "#77b3d4",
        }}
      />
      <path
        d="M49 33c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2s2 .9 2 2v3c0 1.1-.9 2-2 2z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        d="M49 42c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2s2 .9 2 2v3c0 1.1-.9 2-2 2z"
        style={{
          fill: "#76c2af",
        }}
      />
      <g className="st1">
        <path
          className="st2"
          d="M49 50c0 2.2-1.8 4-4 4H21c-2.2 0-4-1.8-4-4V18c0-2.2 1.8-4 4-4h24c2.2 0 4 1.8 4 4v32z"
        />
      </g>
      <path
        d="M48 48c0 2.2-1.8 4-4 4H20c-2.2 0-4-1.8-4-4V16c0-2.2 1.8-4 4-4h24c2.2 0 4 1.8 4 4v32z"
        style={{
          fill: "#e0995e",
        }}
      />
      <path
        style={{
          fill: "#4f5d73",
        }}
        d="M22 12h3v40h-3z"
      />
      <g className="st1">
        <path
          className="st2"
          d="M20 22c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2s.9-2 2-2h3c1.1 0 2 .9 2 2zM20 30c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2s.9-2 2-2h3c1.1 0 2 .9 2 2zM20 38c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2s.9-2 2-2h3c1.1 0 2 .9 2 2zM20 46c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2s.9-2 2-2h3c1.1 0 2 .9 2 2z"
        />
      </g>
      <path
        className="st8"
        d="M20 20c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2s.9-2 2-2h3c1.1 0 2 .9 2 2zM20 28c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2s.9-2 2-2h3c1.1 0 2 .9 2 2zM20 36c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2s.9-2 2-2h3c1.1 0 2 .9 2 2zM20 44c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2s.9-2 2-2h3c1.1 0 2 .9 2 2z"
      />
      <circle className="st9" cx={36} cy={27} r={4} />
      <path
        className="st9"
        d="M42 39.3c0 3-2.7 2.7-6 2.7s-6 .3-6-2.7 2.7-7.3 6-7.3 6 4.2 6 7.3z"
      />
    </g>
  </svg>
)

export default SvgComponent
