import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st2{opacity:.2}.st3{fill:#231f20}.st5{fill:none;stroke:#fff;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <circle
      cx={32}
      cy={32}
      r={32}
      style={{
        fill: "#e0995e",
      }}
    />
    <g className="st2">
      <circle className="st3" cx={35.1} cy={19} r={5} />
    </g>
    <g className="st2">
      <path
        className="st3"
        d="M18.5 54c-1 0-1.9-.6-2.3-1.5-.6-1.3 0-2.7 1.3-3.3C28.1 44.4 27.6 39 27.6 39c-.2-.8.1-1.7.7-2.3.6-.6 1.5-.8 2.3-.6.5.1 13.2 3.4 14.8 15 .2 1.4-.8 2.6-2.1 2.8-1.4.2-2.6-.8-2.8-2.1-.8-5.5-5.4-8.5-8.4-9.9-1 3.3-3.9 7.9-12.6 11.8-.3.2-.7.3-1 .3z"
      />
    </g>
    <g className="st2">
      <path
        className="st3"
        d="M21.4 39.5c-1.4 0-2.5-1.1-2.5-2.5 0-7.4 7-10.5 10.7-11.1.9-.1 1.7.2 2.3.8.2.2 5.3 6 11.5 3 1.2-.6 2.7-.1 3.4 1.1.6 1.2.1 2.7-1.1 3.4-6.8 3.4-13.2 0-16.4-3.1-1.9.6-5.3 2.2-5.3 5.9-.1 1.4-1.2 2.5-2.6 2.5z"
      />
    </g>
    <g className="st2">
      <path
        className="st3"
        d="M31.5 41.5c-1.2 0-2.3-.7-2.8-1.9-3.5-8.8.2-12.9.7-13.3l4.2 4.2.1-.1s-1.5 2 .5 7c.6 1.5-.1 3.3-1.7 3.9-.3.1-.7.2-1 .2z"
      />
    </g>
    <circle
      cx={35.1}
      cy={16}
      r={5}
      style={{
        fill: "#fff",
      }}
    />
    <path
      className="st5"
      d="M18.5 48.5c13-5.8 11.5-13 11.5-13s11.5 2.9 13 13M44.5 29c-8 4-14.5-3.6-14.5-3.6s-8.6 1.4-8.6 8.6"
    />
    <path
      d="M31.5 25.4s-2.9 2.9 0 10.1"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeWidth: 6,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
)

export default SvgComponent
