import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st4{fill:#e0995e}"}</style>
    <g id="Layer_1">
      <circle
        cx={32}
        cy={32}
        r={32}
        style={{
          fill: "#77b3d4",
        }}
      />
      <g
        style={{
          opacity: 0.2,
        }}
      >
        <path
          d="M37 14H27c-1.7 0-3 1.3-3 3v29l5.3 8 2.7 4 2.7-4 5.3-8V17c0-1.7-1.3-3-3-3z"
          style={{
            fill: "#231f20",
          }}
        />
      </g>
      <path
        d="M40 21c0 1.7-1.3 3-3 3H27c-1.7 0-3-1.3-3-3v-6c0-1.7 1.3-3 3-3h10c1.7 0 3 1.3 3 3v6z"
        style={{
          fill: "#c75c5c",
        }}
      />
      <path className="st4" d="M24 20h4v24h-4z" />
      <path
        style={{
          fill: "#f5cf87",
        }}
        d="M28 20h8v26h-8z"
      />
      <path className="st4" d="M36 20h4v24h-4z" />
      <path
        style={{
          fill: "#4f5d73",
        }}
        d="m32 56 2.7-4h-5.4z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="m24 44 5.3 8h5.4l5.3-8h-2l-2-2-4 4-4-4-2 2z"
      />
    </g>
  </svg>
)

export default SvgComponent
