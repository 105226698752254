import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import SendIcon from '@material-ui/icons/Send';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import axios from "axios";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import useStyles from './form-style';

function SignRequest() {

  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('isTruthy', value => value);
  });

  const [openNotif, setNotif] = useState(false);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleClose = () => {
    setNotif(false);
  };

  async function submit() {
    let params = {
      subject: 'Sign Request',
      replyTo:values.email,
      flag:'b',
      html: "Sign Request form submission <b>" + values.name + "</b><br/>" + values.address + "<br/>"+values.phone+"<br/>"+values.email+"<br/>",
    }

    await axios.post('https://hcdsb-strapi.herokuapp.com/send', params).catch(function (error) {
      if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        return
      }
    });
    setNotif(true);
    setValues({
      name: '',
      email: '',
      phone: '',
      address: ''
    });

  }

  return (
    <div className={classes.pageWrap}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key="top right"
        open={openNotif}
        autoHideDuration={4000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Request has been sent</span>}
      />
      <Container maxWidth="md" className={classes.innerWrap}>
          <div className={classes.fullFromWrap}>
            <Typography
              variant="h3"
              align="center"
              className={classes.title}
              gutterBottom
            >
              Lawn Sign Request
            </Typography>
            <Typography className={classes.desc}>
              If you'd like a sign for your lawn, please leave your information below. We'll get in touch to arrange a drop off as soon as possible.
            </Typography>
            <div className={classes.form}>
              <ValidatorForm
                name="contact"
                onSubmit={submit}
                onError={errors => console.log(errors)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="filled"
                      className={classes.input}
                      label='Name'
                      onChange={handleChange('name')}
                      name="name"
                      value={values.name}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="filled"
                      className={classes.input}
                      label='Email'
                      onChange={handleChange('email')}
                      name="email"
                      value={values.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['This field is required', 'email is not valid']}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="filled"
                      className={classes.input}
                      label='Phone'
                      onChange={handleChange('phone')}
                      name="phone"
                      value={values.phone}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="filled"
                      className={classes.input}
                      label='Address'
                      onChange={handleChange('address')}
                      name="address"
                      value={values.address}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  </Grid>
                </Grid>
                <div className={classes.btnArea}>

                  <Button variant="contained" type="submit" color="secondary" size="large">
                    Send Request
                    <SendIcon className={classes.rightIcon} />
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </div>
      </Container>
    </div>
  );
}

export default (SignRequest);
