import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import Rainbow from "../../assets/svg/Services/rainbow-svgrepo-com";
import Megaphone from "../../assets/svg/Services/megaphone-svgrepo-com";
import Running from "../../assets/svg/Services/running-svgrepo-com";
import Compose from "../../assets/svg/Services/compose-svgrepo-com";
import Pencil from "../../assets/svg/Services/pencil-svgrepo-com";
import Brightness from "../../assets/svg/Services/brightness-svgrepo-com";
import Globe from "../../assets/svg/Services/globe-svgrepo-com";
import Heart from "../../assets/svg/Services/Heart";
import Contacts from "../../assets/svg/Services/Contacts";
import Globe2 from "../../assets/svg/Services/Globe";
import Key from "../../assets/svg/Services/Key";
import Booklet from "../../assets/svg/Services/Booklet";
import Shoeprints from "../../assets/svg/Services/Shoeprints";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "rainbow":
      getIcon = <Rainbow style={{ height: 55, width: 55 }} />;
      break;
      case "brightness":
        getIcon = <Brightness style={{ height: 55, width: 55 }} />;
        break;
        case "globe":
          getIcon = <Globe style={{ height: 55, width: 55 }} />;
          break;
      case "megaphone":
        getIcon = <Megaphone style={{ height: 55, width: 55 }} />;
        break;
    case "running":
      getIcon = <Running style={{ height: 55, width: 55 }} />;
      break;
    case "compose":
        getIcon = <Compose style={{ height: 55, width: 55 }} />;
        break;
    case "pencil":
      getIcon = <Pencil style={{ height: 55, width: 55 }} />;
      break;    
    case "roller":
      getIcon = <RollerIcon style={{ height: 55, width: 55 }} />;
      break;
    case "monitor":
      getIcon = <MonitorIcon style={{ height: 55, width: 55 }} />;
      break;
    case "browser":
      getIcon = <BrowserIcon style={{ height: 55, width: 55 }} />;
      break;
    case "printer":
      getIcon = <PrinterIcon style={{ height: 55, width: 55 }} />;
      break;
    case "heart":
        getIcon = <Heart style={{ height: 55, width: 55 }} />;
        break;
    case "contacts":
          getIcon = <Contacts style={{ height: 55, width: 55 }} />;
          break;
    case "globe2":
            getIcon = <Globe2 style={{ height: 55, width: 55 }} />;
            break;
    case "key":
      getIcon = <Key style={{ height: 55, width: 55 }} />;
      break;
    case "booklet":
        getIcon = <Booklet style={{ height: 55, width: 55 }} />;
        break;
    case "shoeprints":
          getIcon = <Shoeprints style={{ height: 55, width: 55 }} />;
          break;
    default:
      getIcon = <RollerIcon  style={{ height: 55, width: 55 }}/>;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 120px) {
    margin: 0 auto;
  };
  align-Items: center;
  display:flex;
  justify-Content:space-around;
  
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  align-Items: center;
  text-Align: center;
  @media (max-width: 860px) {
    padding: 20px 0;
  };
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  align-Items: center;
  text-Align: center;
`;