import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1{fill:#1d1e1f}"}</style>
    <g id="Layer_1">
      <circle
        cx={32}
        cy={32}
        r={32}
        style={{
          fill: "#76c2af",
        }}
      />
      <path
        className="st1"
        d="M26.1 36.7c.6-1.6 1.6-4.3.1-9.5S22.5 20 19.5 20c0 0-5.2-.3-5.5 7.9-.4 9 2.8 11.3 3.8 16.4l7.5-2.1c-.2-2.2.4-4.3.8-5.5zM26.3 45.1l-8.1 2.3c.4 5.3 3 9.6 7.5 8.4 4.5-1.3 2.4-7.5.6-10.7zM37.9 28.7c-.6-1.6-1.6-4.3-.1-9.5s3.7-7.2 6.7-7.2c0 0 5.2-.3 5.5 7.9.4 9-2.8 11.3-3.8 16.4l-7.5-2.1c.2-2.2-.4-4.3-.8-5.5zM37.7 37.1l8.1 2.3c-.4 5.3-3 9.6-7.5 8.4-4.5-1.3-2.4-7.5-.6-10.7z"
      />
    </g>
  </svg>
)

export default SvgComponent
