import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 64 64",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <circle
      cx={32}
      cy={32}
      r={32}
      style={{
        fill: "#e0e0d1",
      }}
    />
    <g
      style={{
        opacity: 0.2,
      }}
    >
      <path
        d="M32 10C18.7 10 8 20.7 8 34c0 5.3 1.7 10.2 4.6 14.2.2.3 2.6 2.6 5.2 0l2.8-2.8 2.8-2.8 2.8-2.8s1.7-1.6 1.7-5.7c0-2.2 1.8-4 4-4s4 1.8 4 4c0 0-.3 3.7 1.7 5.7l2.1 2.1.7.7 2.1 2.1.7.7 1.8 1.8.8.8.3.3s2.8 2.8 5.2 0c2.9-4 4.6-8.9 4.6-14.2C56 20.7 45.3 10 32 10z"
        style={{
          fill: "#231f20",
        }}
      />
    </g>
    <path
      d="M17.9 46.1C14.2 42.5 12 37.5 12 32c0-11 9-20 20-20s20 9 20 20c0 5.5-2.2 10.5-5.9 14.1 0 0 2.8 2.8 5.2 0 2.9-4 4.6-8.9 4.6-14.2C56 18.7 45.3 8 32 8S8 18.7 8 32c0 5.3 1.7 10.2 4.6 14.2.3.2 2.6 2.6 5.3-.1z"
      style={{
        fill: "#c75c5c",
      }}
    />
    <path
      d="M32 12c-11 0-20 9-20 20 0 5.5 2.2 10.5 5.9 14.1l2.8-2.8C17.8 40.4 16 36.4 16 32c0-8.8 7.2-16 16-16s16 7.2 16 16c0 4.4-1.8 8.4-4.7 11.3l1.8 1.8.8.8.3.3C49.8 42.5 52 37.5 52 32c0-11-9-20-20-20z"
      style={{
        fill: "#e0995e",
      }}
    />
    <path
      d="M32 16c-8.8 0-16 7.2-16 16 0 4.4 1.8 8.4 4.7 11.3l2.8-2.8C21.3 38.3 20 35.3 20 32c0-6.6 5.4-12 12-12s12 5.4 12 12c0 3.3-1.3 6.3-3.5 8.5l2.1 2.1.7.7C46.2 40.4 48 36.4 48 32c0-8.8-7.2-16-16-16z"
      style={{
        fill: "#f5cf87",
      }}
    />
    <path
      d="M32 20c-6.6 0-12 5.4-12 12 0 3.3 1.3 6.3 3.5 8.5l2.8-2.8C24.9 36.2 24 34.2 24 32c0-4.4 3.6-8 8-8s8 3.6 8 8c0 2.2-.9 4.2-2.3 5.7l2.1 2.1.7.7c2.2-2.2 3.5-5.2 3.5-8.5 0-6.6-5.4-12-12-12z"
      style={{
        fill: "#76c2af",
      }}
    />
    <path
      d="M32 24c-4.4 0-8 3.6-8 8 0 2.2.9 4.2 2.3 5.7 0 0 1.7-1.6 1.7-5.7 0-2.2 1.8-4 4-4s4 1.8 4 4c0 0-.3 3.7 1.7 5.7 1.4-1.4 2.3-3.4 2.3-5.7 0-4.4-3.6-8-8-8z"
      style={{
        fill: "#4f5d73",
      }}
    />
  </svg>
)

export default SvgComponent
